import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { get, lowerCase } from 'lodash-es'

export default function usePendingChange() {
  const getPendingChangeStatus = (model) => {
    let status = 'Sync successful'
    let message = 'Your field hardware is up to date'

    if (hasPendingChange(model)) {
      status = 'Sync in progress'

      const type = get(model, 'pendingChanges.type', 'update')

      if (type === 'create') {
        message = 'Creating your configuration on field hardware'
      }

      if (type === 'update') {
        message = 'Updating your configuration on field hardware'
      }

      if (type === 'delete') {
        message = 'Deleting your configuration from field hardware'
      }
    }

    return {
      status,
      message,
    }
  }

  const hasPendingChange = (model) => {
    return get(model, 'pendingChanges.hasChange', false)
  }

  const getPendingChangeDisabledReason = (model) => {
    if (hasPendingChange(model)) {
      const status = getPendingChangeStatus(model)

      return `You cannot edit or delete this because ${lowerCase(status.status)}: ${status.message}.`
    }

    return null
  }

  const PendingChangeStatusIcon = ({
    model,
    position = 'right',
  }) => {
    const status = getPendingChangeStatus(model)

    return (
      <Tippy
        content={
          <div>
            <div className="font-bold">{status.status}:</div>
            <div>{status.message}</div>
          </div>
        }
        theme="light"
        placement={position}
      >
        <span className="mr-2 p-1 md:mr-4">
          <span
            className={classNames({
              'bg-green-600': !hasPendingChange(model),
              'bg-yellow-500': hasPendingChange(model),
            }, 'w-3 h-3 inline-block rounded-full')}
          />
        </span>
      </Tippy>
    )
  }

  return {
    hasPendingChange,
    getPendingChangeDisabledReason,
    getPendingChangeStatus,
    PendingChangeStatusIcon,
  }
}
