import { get } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import { Anchor, RouterButton } from '@/Components/form/Buttons'
import { pageAlertState } from '@/Config/Atoms/General'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useTitle from '@/Utilities/useTitle'

const CenterContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 113px);
  width: 100%;
`

const Content = styled.div`
  width: auto;
  text-align: center;
`

const openTermsAndConditions = (e) => {
  e.preventDefault()
  e.stopPropagation()

  window.open('https://www.futureops.com/terms-and-conditions', '_blank')
}

export default function TermsAcceptance() {
  const {
    user,
    fetchUser,
  } = useAuth()
  const apiClient = useApiClient()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)

  const setAlert = useSetRecoilState(pageAlertState)

  useTitle('Action Required')

  useEffect(() => {
    if (get(user, 'terms_accepted')) {
      if (firstLoad) {
        setAlert({
          type: 'info',
          content: 'You have already accepted the terms and conditions.',
        })
      }

      navigate('/dashboard')
    }

    setFirstLoad(false)
  }, [user, firstLoad])

  const acceptTerms = useCallback(async () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)

    const response = await apiClient.post('user/accept-terms')

    if (get(response, 'data.success', false)) {
      await fetchUser()

      setAlert({
        type: 'success',
        content: 'Terms and conditions accepted.',
      })

      setTimeout(() => {
        navigate('/dashboard')

      }, 500)
    } else {
      setAlert({
        type: 'warning',
        content: 'An error occurred while accepting the terms and conditions. Please try again.',
      })
    }

    setIsLoading(false)
  }, [
    apiClient,
    fetchUser,
    isLoading,
  ])

  return (
    <CenterContent>
      <Content>
        <div className="text-6xl">
          Action Required
        </div>

        <div className="m-6 max-w-3xl text-xl text-gray-500">
          To use FutureOps, you must accept the terms and conditions.
        </div>

        <div className="m-6 mb-10 max-w-3xl text-xl text-gray-500">
          To view the terms and conditions, please visit the <a onClick={openTermsAndConditions} className="cursor-pointer text-primary">Terms & Conditions</a> page.
        </div>

        <div className="items-bottom flex justify-center">
          <Anchor
            className="mr-2 min-w-24 text-center"
            onClick={acceptTerms}
            isLoading={isLoading}
          >
            Accept
          </Anchor>

          <RouterButton href="/user/logout">
            Decline
          </RouterButton>
        </div>
      </Content>
    </CenterContent>
  )
}
